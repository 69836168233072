import {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useResizeObserver from "@react-hook/resize-observer";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { Badge } from "../shadcn/components/badge";
import { Button } from "../shadcn/components/button";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ReloadIcon,
  RotateCounterClockwiseIcon,
} from "@radix-ui/react-icons";
import { Input } from "../shadcn/components/input";
import { LoadingView } from "./Loading";
import { Citation, ExactMatches } from "../types";
import { DocViewerContext } from "../contexts/DocViewerContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/components/dropdown-menu";
import { MoreVertical } from "lucide-react";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";
import {
  createPdfUrl,
  downloadData,
  individualDocClick,
  searchIndividualDoc,
} from "../utils/apiCalls";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/components/accordion";
import { cn } from "../shadcn/lib/utils";
import React from "react";
import { PdfDocumentViewer } from "./PdfViewer";
import * as pdfjsLib from "pdfjs-dist";
import { PdfCitation } from "./PdfViewer/PdfHighlighter/types";

pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const ClickableCitation = (props: {
  queryId: string | null;
  citation: Citation;
}) => {
  const authInfo = useAuthInfo();
  const { setCitations, setPageNumber } = useContext(DocViewerContext);
  return (
    <div
      className="text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100"
      key={props.citation.id}
      onClick={() => {
        setCitations([
          {
            id: props.citation.id || "",
            match: props.citation.text,
            exactMatch: false,
            page: props.citation.page,
            isSelected: true,
          },
        ]);
        setPageNumber(props.citation.page);
        if (props.queryId) {
          individualDocClick(
            props.queryId,
            "result",
            props.citation.id!,
            authInfo.accessToken ?? null
          );
        }
      }}
    >
      {props.citation.text.slice(0, 100)}...
    </div>
  );
};

export const IndividualDocSearchView = (props: { question?: string }) => {
  const authInfo = useAuthInfo();
  const { docToView, setCitations, setPageNumber } =
    useContext(DocViewerContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<{
    contentMatch: Citation[];
    exactMatch: ExactMatches | null;
    queryId: string;
  } | null>(null);
  const [suggestionResults, setSuggestionResults] = useState<{
    question: string;
    docId: string;
    results: Citation[];
  } | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSuggestions = async () => {
    if (props.question) {
      setSuggestionResults(null);
      try {
        for await (const output of searchIndividualDoc(
          props.question,
          docToView?.docId ?? "",
          false,
          authInfo.accessToken ?? null
        )) {
          setSuggestionResults({
            question: props.question,
            docId: docToView?.docId ?? "",
            results: output.search_results,
          });
        }
      } catch (error: any) {
        console.error("There was an error getting suggestions", error);
        toast.error("Unable to get suggestions");
      }
    }
  };

  const handleSearch = async (searchTerm: string) => {
    setSearchLoading(true);
    setSearchResults(null);
    setCitations([]);
    try {
      for await (const output of searchIndividualDoc(
        searchTerm,
        docToView?.docId ?? "",
        true,
        authInfo.accessToken ?? null
      )) {
        setSearchResults({
          contentMatch: output.search_results,
          exactMatch: output.pages_with_exact_match,
          queryId: output.query_id,
        });
      }
    } catch (error: any) {
      console.error("There was an error running the search", error);
      toast.error("Unable to run search");
    }
    setSearchLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(searchTerm);
    }
  };

  useEffect(() => {
    if (
      suggestionResults === null ||
      suggestionResults.docId !== docToView?.docId ||
      suggestionResults.question !== props.question
    ) {
      handleSuggestions();
    }
    inputRef.current?.focus();
  }, [docToView?.docId, props.question]);

  return (
    <div className="h-[calc(100vh-350px)] p-2 overflow-y-auto space-y-2">
      <div className="flex items-center space-x-2">
        <div className="flex-grow">
          <Input
            type="search"
            className="text-sm"
            placeholder="Find in document..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={searchLoading}
            ref={inputRef}
          />
        </div>
        <Button
          variant="default"
          size="sm"
          onClick={() => handleSearch(searchTerm)}
          disabled={searchLoading}
        >
          Search{" "}
          {searchLoading && (
            <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
          )}
        </Button>
      </div>
      <Accordion type="single" collapsible className="w-full">
        {searchResults !== null && (
          <AccordionItem key="content-match" value="content-match">
            <AccordionTrigger>
              <div className="flex space-x-4">
                <span>Content Match</span>
                <Badge className="text-xs bg-gray-400">
                  {searchResults.contentMatch.length}
                </Badge>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {searchResults.contentMatch.map((result) => (
                <ClickableCitation
                  citation={result}
                  queryId={searchResults.queryId}
                  key={result.id}
                />
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
        {searchResults?.exactMatch && (
          <AccordionItem key="exact-match" value="exact-match">
            <AccordionTrigger>
              <div className="flex space-x-4">
                <span>Exact Match</span>
                <Badge className="text-xs bg-gray-400">
                  {searchResults.exactMatch.total}
                </Badge>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {searchResults.exactMatch.page_matches.map((match) => (
                <div
                  className="text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100"
                  key={match.page}
                  onClick={() => {
                    setCitations([
                      {
                        id: `searchTermCitation`,
                        match: searchTerm,
                        exactMatch: true,
                        page: match.page,
                        isSelected: true,
                      },
                    ]);
                    setPageNumber(match.page);
                    individualDocClick(
                      searchResults.queryId,
                      "page",
                      match.id,
                      authInfo.accessToken ?? null
                    );
                  }}
                >
                  Page {match.page}
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
        {suggestionResults !== null && suggestionResults.results.length > 0 && (
          <AccordionItem key="suggestions" value="suggestions">
            <AccordionTrigger>
              <span>Suggestions</span>
              <Badge className="text-xs bg-gray-400">
                {suggestionResults.results.length}
              </Badge>
            </AccordionTrigger>
            <AccordionContent>
              {suggestionResults.results.map((result) => (
                <ClickableCitation citation={result} queryId={null} />
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
      </Accordion>
    </div>
  );
};

const AdditionalViewerControls = (props: { hideAtlasWidget?: boolean }) => {
  const authInfo = useAuthInfo();
  const { docToView, pageNumber, citations } = useContext(DocViewerContext);

  const onLinkClick = async () => {
    let link = `${window.location.origin}/doc-chat?docId=${docToView?.docId}&page=${pageNumber}`;
    if (citations[0]?.match) {
      link += `&text=${citations[0].match}`;
    }
    await navigator.clipboard.writeText(link);
    toast.success("Copied to clipboard");
  };

  const onClickDownload = async () => {
    if (docToView) {
      const success = await downloadData(
        docToView.docId,
        authInfo.accessToken ?? null
      );
      if (!success) {
        toast.error("Error exporting data, please try again");
      }
    }
  };

  return (
    <DropdownMenu modal={true}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <MoreVertical className="h-4 w-4" />
          <span className="sr-only">More</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="max-w-[400px]">
        {/* <DropdownMenuItem onClick={() => setOwnersOpen(true)}>
          Owners
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onLinkClick}>
          Link to Section
        </DropdownMenuItem> */}
        <DropdownMenuItem onClick={onClickDownload}>
          Download Document
        </DropdownMenuItem>
        {/* {docMetadata?.regulatory_doc_id && (
          <DropdownMenuItem
            onClick={() =>
              navigate(
                `/regulatory-doc/overview/${docMetadata.regulatory_doc_id}`
              )
            }
          >
            Regulatory Doc Summary
          </DropdownMenuItem>
        )}
        {(docMetadata?.editable_doc_id || docMetadata?.approval_flow_id) && (
          <DropdownMenuItem
            onClick={() =>
              navigate(
                `/policy-repo/doc-view/${
                  docMetadata.editable_doc_id ?? docMetadata.id
                }`
              )
            }
          >
            Policy Repository
          </DropdownMenuItem>
        )} */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const NumPagesControls = () => {
  const { pageNumber, numPages, setPageNumber } = useContext(DocViewerContext);

  return (
    <div className="flex items-center space-x-1">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber((prevPage) => prevPage - 1)}
          >
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="sr-only">Previous Page</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Previous Page</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            disabled={pageNumber >= numPages!}
            onClick={() => setPageNumber((prevPage) => prevPage + 1)}
          >
            <ArrowRightIcon className="w-4 h-4" />
            <span className="sr-only">Next Page</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Next Page</TooltipContent>
      </Tooltip>
      <div className="flex items-center space-x-1">
        <Input
          type="number"
          min="1"
          max={numPages!}
          value={pageNumber}
          onChange={(e) => setPageNumber(Number(e.target.value))}
          className="w-18 text-center"
          aria-label="Go to page"
          disabled={numPages === null}
        />
        <div className="w-12">/ {numPages}</div>
      </div>
    </div>
  );
};

export const BaseDocViewerControls = (props: {
  hideAtlasWidget?: boolean;
  children?: React.ReactNode;
}) => {
  const { docToView, setRotation, numPages, docMetadata } =
    useContext(DocViewerContext);

  return (
    <div className="space-y-2 w-full">
      <div>
        <Tooltip>
          <TooltipTrigger>
            <a
              href={`/doc-chat?page=1&docId=${docToView?.docId}`}
              className={cn(
                "max-w-[100%] text-md font-semibold truncate text-ellipsis overflow-hidden whitespace-nowrap hover:underline hover:text-blue-500",
                window.location.pathname === "/doc-chat" &&
                  "pointer-events-none"
              )}
            >
              {decodeURI(docMetadata?.name ?? "")}
            </a>
          </TooltipTrigger>
          <TooltipContent>{decodeURI(docMetadata?.name ?? "")}</TooltipContent>
        </Tooltip>
      </div>
      <div className="flex justify-between max-w-[100%] items-center space-x-2 overflow-x-auto">
        <div className="flex items-center space-x-2">
          {numPages && (
            <>
              <NumPagesControls />
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => setRotation((prev) => prev - 90)}
                  >
                    <RotateCounterClockwiseIcon className="w-4 h-4" />
                    <span className="sr-only">Rotate Left</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Rotate Left</TooltipContent>
              </Tooltip>
            </>
          )}
          {props.children}
        </div>
        {docToView && (
          <AdditionalViewerControls hideAtlasWidget={props.hideAtlasWidget} />
        )}
      </div>
    </div>
  );
};

const useSize = (target: React.RefObject<any>) => {
  const [size, setSize] = useState<any>();

  useEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry: any) => setSize(entry.contentRect));
  return size;
};

type DocViewerProps = {
  onCitationsUpdate?: (citation: PdfCitation) => void;
};
export const DocViewer: React.FC<DocViewerProps> = ({ onCitationsUpdate }) => {
  const {
    docToView,
    pageNumber,
    setPageNumber,
    setNumPages,
    citations,
    setCitations,
  } = useContext(DocViewerContext);
  const authInfo = useAuthInfo();

  const fileUrl = useMemo(() => {
    return createPdfUrl(docToView?.docId ?? "", docToView?.historyId ?? "");
  }, [docToView?.docId, docToView?.historyId]);

  const fileOptions = useMemo(() => {
    return {
      httpHeaders: {
        Authorization: `Bearer ${authInfo.accessToken}`,
      },
    };
  }, [authInfo.isLoggedIn]);

  const defaultSetCitations = (citationText: PdfCitation) => {
    setCitations((prevCitations) => {
      const rangeCitaitonIndex = prevCitations.findIndex(
        (cit) => cit.id === citationText.id
      );
      if (rangeCitaitonIndex === -1) {
        return [citationText, ...prevCitations];
      } else {
        prevCitations[rangeCitaitonIndex] = citationText;
        return [...prevCitations];
      }
    });
  };

  const handleCitationUpdate = (citation: PdfCitation) => {
    if (onCitationsUpdate) {
      onCitationsUpdate(citation);
    } else {
      defaultSetCitations(citation);
    }
  };

  return (
    <PdfDocumentViewer
      url={fileUrl}
      fileOptions={fileOptions}
      documentLoadingContnet={<LoadingView />}
      pageLoadingContent={<LoadingView customText={``} />}
      pageNumber={pageNumber}
      citations={citations}
      onUpdateCitation={handleCitationUpdate}
      onUpdatePageNumber={(pageNumber) => {
        setPageNumber(pageNumber);
      }}
      onUpdateNumPages={(numPages) => {
        setNumPages(numPages);
      }}
    />
  );
};

export const DocViewerCitation = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    docId: string;
    hideAtlasWidget?: boolean;
    children?: React.ReactNode;
    onCitationsUpdate?: (citations: PdfCitation) => void;
  }
>(({ className, docId, hideAtlasWidget, children, onCitationsUpdate }, ref) => {
  const { docToView, setDocToView } = useContext(DocViewerContext);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (docId && (docToView?.docId !== docId || docToView === null)) {
      setDocToView({
        docId: docId,
      });
    }
  }, [docId]);

  return (
    <div className="space-y-4 mt-2 pl-2">
      <BaseDocViewerControls hideAtlasWidget={hideAtlasWidget}>
        {children}
      </BaseDocViewerControls>
      <div ref={scrollBoxRef} className={cn("noverflow-y-auto", className)}>
        {docToView && <DocViewer onCitationsUpdate={onCitationsUpdate} />}
      </div>
    </div>
  );
});
